import { useEffect, useState } from "react"

const CookieConsent = () => {
	const [isVisible, setIsVisible] = useState(false)
	const [isExpanded, setIsExpanded] = useState(false)
	const [preferences, setPreferences] = useState({
		essential: true,
		analytics: false,
		marketing: false,
	})

	useEffect(() => {
		const storedConsent = localStorage.getItem("cookieConsent")
		if (!storedConsent) {
			setIsVisible(true)
		}

		// Handle scroll disappearance
		const handleScroll = () => {
			const scrolledHeight = window.scrollY + window.innerHeight
			const threshold = window.innerHeight * 1.5
			if (scrolledHeight > threshold) {
				setIsVisible(false)
			} else if (!localStorage.getItem("cookieConsent")) {
				setIsVisible(true)
			}
		}

		window.addEventListener("scroll", handleScroll)
		return () => window.removeEventListener("scroll", handleScroll)
	}, [])

	const handleAcceptAll = () => {
		localStorage.setItem(
			"cookieConsent",
			JSON.stringify({ essential: true, analytics: true, marketing: true }),
		)
		setIsVisible(false)
	}

	const handleSavePreferences = () => {
		localStorage.setItem("cookieConsent", JSON.stringify(preferences))
		setIsVisible(false)
	}

	const togglePreference = (type: "analytics" | "marketing") => {
		setPreferences((prev) => ({
			...prev,
			[type]: !prev[type], // Toggle the preference
		}))
	}

	return (
		<div
			style={{
				position: "fixed",
				bottom: isVisible ? "20px" : "-250px", // Slide down when hidden
				left: "20px",
				width: "280px",
				padding: "12px",
				borderRadius: "8px",
				backgroundColor: "#fff",
				fontFamily: "system-ui, sans-serif",
				boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
				zIndex: 9999,
				transition: "bottom 1s ease-in-out",
				border: "1px solid #ddd",
				fontSize: "12px",
				lineHeight: "1.4",
			}}
		>
			<p style={{ marginBottom: "10px" }}>
				We use cookies to enhance your experience. Manage your preferences
				below.
			</p>

			{isExpanded && (
				<div
					style={{
						marginBottom: "10px",
						paddingBottom: "8px",
						borderBottom: "1px solid #eee",
					}}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							marginBottom: "6px",
						}}
					>
						<span>Essential (Required)</span>
						<span
							style={{ fontSize: "11px", fontWeight: "bold", color: "#aaa" }}
						>
							✔ Always On
						</span>
					</div>

					<div
						onClick={() => togglePreference("analytics")}
						onKeyDown={(e) =>
							(e.key === "Enter" || e.key === " ") &&
							togglePreference("analytics")
						}
						role="button"
						tabIndex={0} // Fix: Use tabIndex={0} for keyboard accessibility
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							padding: "6px",
							borderRadius: "5px",
							border: "1px solid #aaa",
							backgroundColor: preferences.analytics ? "#111" : "#f8f8f8",
							color: preferences.analytics ? "#fff" : "#111",
							cursor: "pointer",
							fontSize: "12px",
							marginBottom: "6px",
						}}
					>
						<span>Analytics</span>
						<span>{preferences.analytics ? "On" : "Off"}</span>
					</div>

					<div
						onClick={() => togglePreference("marketing")}
						onKeyDown={(e) =>
							(e.key === "Enter" || e.key === " ") &&
							togglePreference("marketing")
						}
						role="button"
						tabIndex={0} // Fix: Changed from tabIndex={1} to tabIndex={0}
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							padding: "6px",
							borderRadius: "5px",
							border: "1px solid #aaa",
							backgroundColor: preferences.marketing ? "#111" : "#f8f8f8",
							color: preferences.marketing ? "#fff" : "#111",
							cursor: "pointer",
							fontSize: "12px",
						}}
					>
						<span>Marketing</span>
						<span>{preferences.marketing ? "On" : "Off"}</span>
					</div>
				</div>
			)}

			<div style={{ display: "flex", gap: "6px" }}>
				<button
					type="button"
					onClick={handleAcceptAll}
					style={{
						flex: 1,
						padding: "6px",
						borderRadius: "5px",
						border: "1px solid #000",
						backgroundColor: "#111",
						color: "#fff",
						cursor: "pointer",
						fontWeight: "bold",
						fontSize: "11px",
					}}
				>
					Accept All
				</button>
				<button
					type="button"
					onClick={() => setIsExpanded(!isExpanded)}
					style={{
						flex: 1,
						padding: "6px",
						borderRadius: "5px",
						border: "1px solid #aaa",
						backgroundColor: "#f8f8f8",
						color: "#111",
						cursor: "pointer",
						fontSize: "11px",
					}}
				>
					{isExpanded ? "Hide" : "Customize"}
				</button>
				{isExpanded && (
					<button
						type="button"
						onClick={handleSavePreferences}
						style={{
							flex: 1,
							padding: "6px",
							borderRadius: "5px",
							border: "1px solid #000",
							backgroundColor: "#111",
							color: "#fff",
							cursor: "pointer",
							fontWeight: "bold",
							fontSize: "11px",
						}}
					>
						Save
					</button>
				)}
			</div>
		</div>
	)
}

export default CookieConsent
